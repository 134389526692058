<template>
    <div class="contenedorCalendario">
     <b-container>
        <calendar-view
            :items="items"
            :show-date="showDate"
            @click-date="onClickDay"
            @click-item="onClickItem"
            class="theme-default">

            <calendar-view-header
                slot="header"
                slot-scope="t"
                :header-props="t.headerProps"
                @input="setShowDate" />
                
        </calendar-view>
    </b-container>
  </div>
</template>

<script>
	import { CalendarViewHeader } from "vue-simple-calendar"
	import CalendarView from "../../../components/gestionVisitas/calendario/CalendarioPersonalizado.vue"
	import moment from 'moment'

	export default {
		name: 'CalendarioVisitas',
		props:['visitas'],
		data(){
			return{
				showDate: new Date(),
			}
		},
		components: {
			CalendarView,
			CalendarViewHeader
		},
		computed: {
			items(){
				let array = this.visitas.map((element, index) => {
					let visita = {
						id: index,
						startDate: element.fecha,
						total: element.eje+element.eneje+element.plan,
						...element
					}
					return visita
				});
				return array
			}
		},
		methods: {
				
			setShowDate(d) {
				this.showDate = d;
			},
			setSelection(dateRange) {
				this.selectionEnd = dateRange[1]
				this.selectionStart = dateRange[0]
			},
			finishSelection(dateRange) {
				this.setSelection(dateRange)
			},
			onClickDay(d) {
				// this.$emit('visitaSeleccionada', moment(d).format('YYYY-MM-DD'))
				console.log(d)
			},
			onClickItem(e) {
				console.log(e)
				console.log(moment(e.fecha).format('YYYY-MM-DD'))
				this.$emit('visitaSeleccionada', moment(e.fecha).format('YYYY-MM-DD'))
			},
		}
	}
</script>

<style scoped>
    .contenedorCalendario{
        margin-top:50px
    }
</style>